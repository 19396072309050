@import "../../../core/style/colors.scss";

// TABLE
.clientsListContainer {
  margin-top: 2rem;
}

.clientsListBody {
  padding: 0;
}

// ROW
.clientsListRowContainer {
  margin-bottom: 0.8rem;
  border: 1px solid lighten($grey, 7%);
  border-radius: 2px;

  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.12);
  }
}

// Client Avatar | Name | Pace tag
.clientsListRowClientName {
  padding: 1rem 0 1rem 1rem;
}

.clientsListRowAvatar {
  float: left;
}

.clientsListRowClientNameText {
  position: relative;
  top: 4px;
  float: left;
  font-size: 1rem;
  margin-left: 1rem;
}

.clientsListRowClientStatus {
  display: block;
  padding: 2px 5px;
  font-size: 0.8rem;
  color: #fff;
  border-radius: 2px;
}

.behind {
  background: $paleRed;
}
.exceeding {
  background: $green;
}
.ontarget {
  background: $bluish;
}

// Client attendance stats
.clientsListRowClientInfo {
  padding: 1rem 3rem 1rem 1rem;
}

// Client attendance actions
.clientsListRowClientActions {
  padding: 1rem 0;
  background-color: $light-grey;
}
