.btnIconLeft {
  span:nth-child(1) {
    float: left;
    margin-top: 4px;
  }
}

.attendanceDay {
  list-style: none;
  margin: 0;
  padding: 0;
}
.attendanceDay .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
