.messagesList {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.listItem {
  background: #fff;

  &:hover {
    cursor: pointer;
  }
}

.selected {
  background: lighten(#d9d9d9, 7%);
}
