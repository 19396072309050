.wrapper {
  height: 100%;
}

.layout {
  position: relative;
  height: calc(100% + 30px);
}

.sider {
  background: #fff;

  & > div {
    display: flex;
    flex-direction: column;
  }
}
