$turquoise: #78cdd1;
$aquamarine: #4fe9b3;
$charcoal: #303030;
$whiteTwo: #e0e0e0;
$lightBlueGrey: #f1f6fa;
$darkBlueGrey: #283845;
$darkBlueGreyTwo: #202c39;
$bluish: #2378d6;
$bluishGrey: #7c8f9e;
$grey: #d1d1d6;
$inputGrey: #bdbdbd;
$light-grey: #fafafa !default;
$breadcrumb-bg: #fafafa !default;
$formGrey: #757575;
$input-placeholder-color: #bdbdbd !default;
$input-plaintext-color: #424242 !default;
$green: #2ecc71;
$paleGreen: #c4d437;
$paleOrange: #fa7b46;
$transparent: rgba(0, 0, 0, 0);
$paleRed: #d45d5d;
$greyishBrown: #424242;
