.autoCompleteDropdownContainer {
  border: 1px solid #dadada;
}

.item {
  padding: 0.3rem;
  background-color: #ffffff;
  cursor: "pointer";
}

.active {
  background-color: #fafafa;
  cursor: "pointer";
}
