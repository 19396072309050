@import "../../../core/style/colors.scss";

.managerLayout {
  background: $light-grey;
  transition: margin 0.2s;
}
.managerLayoutHeader {
  background: $grey;
}

// Sider
.managerLayoutSider {
  // Override .ant-layout-sider-children
  & > div:first-child {
    display: flex;
    flex-direction: column;
  }
}
