.container {
  position: relative;
  height: 100%;
}

.contentContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.chatHeader {
  background: white;
  border-bottom: 1px solid #d9d9d9;
}

.messagesContainer {
  flex: 1;
  background: white;
  padding: 1.5rem;
  overflow-y: auto;
}

.messageInputForm {
  background: white;
  padding: 1.5rem;
  border-top: 1px solid #d9d9d9;
}

.messageInput {
  flex: 1;
}
