$green: #6fcfc4;
$blue: #4d81da;

.messageContainer {
  width: 100%;
  margin-bottom: 1rem;
  clear: both;
  overflow: hidden;
}

.meta {
  color: #bebebe;
}

.right {
  float: right;
}

.messageBody {
  max-width: 50%;
  display: inline-block;
  background: $green;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  color: white;
  clear: both;
}

.incoming {
  float: right;
  background: $blue;
}
